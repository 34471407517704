import { IonEyeOff } from '@/assets/icons/funds/IonEyeOff';
import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import { OouiEye } from '@/assets/icons/funds/OouiEye';
import BaseSkeleton from '@/components/base/baseSkeleton';
import { WalletTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import ProjectSetting from '@/setting/projectSetting';
import { useModel } from '@@/exports';
import { history } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import RenderUtil from '../../../../utils/RenderUtil';
import { FormatUtils } from '@/utils/format';

interface IProps {
  fundsUnderCurrency: any;
  loading: boolean;
}

export default ({ fundsUnderCurrency, loading }: IProps) => {
  const { t } = useTranslation();
  const { coinPrecision } = useModel('appInfo');
  const { exchangeSetConvert, isShow, setIsShow, maskPrice } =
    useModel('exchange');
  return (
    <div>
      {!loading && (
        <div className="px-4 mt-2 md:min-w-[20vw]  md:border-borderColor  py-1">
          <div className="flex justify-between">
            <div className="flex items-center pb-2">
              <span className="text-backContrastColor mr-2 border-b-1 border-dashed border-backgroundAuxiliaryColor ">
                {t('总资产')}
              </span>
              {isShow && (
                <OouiEye
                  className="text-auxiliaryTextColor text-base"
                  onClick={() => {
                    setIsShow(false);
                  }}
                />
              )}
              {!isShow && (
                <IonEyeOff
                  className="text-auxiliaryTextColor text-base"
                  onClick={() => {
                    setIsShow(true);
                  }}
                />
              )}
            </div>
            <MaterialSymbolsAssignmentRounded
              onClick={() => {
                history.push(
                  PageEnum.ORDER_HISTORY + '?walletType=' + WalletTypeEnum.COIN,
                );
              }}
              width="1.5em"
              height="1.5em"
              className="cursor-pointer text-foreground pt-1"
            />
          </div>
          <div className="flex items-end">
            <div className="flex items-end mr-1 text-backContrastColor">
              <div className="text-xl mr-1 flex items-center">
                <div className="text-xl">
                  {maskPrice(
                    RenderUtil.FormatAmount(
                      fundsUnderCurrency?.availableBalance +
                        fundsUnderCurrency?.freezeBalance,
                        FormatUtils.getPrecision(fundsUnderCurrency?.name,coinPrecision),
                    ),
                  )}
                </div>
              </div>
            </div>
            <div className="text-auxiliaryTextColor text-xs">
              ≈{' '}
              {exchangeSetConvert({
                price:
                  fundsUnderCurrency?.convertUsd +
                  fundsUnderCurrency?.freezeConvertUsd,
                isJoin: true,
              })}
            </div>
          </div>
          <div className="flex items-center gap-8">
            <div className="flex flex-col mt-3">
              <span className="text-auxiliaryTextColor text-xs">
                {t('可用资产')}
              </span>
              <span className="mt-2">
                {maskPrice(
                  RenderUtil.FormatAmount(
                    fundsUnderCurrency?.availableBalance,
                    FormatUtils.getPrecision(fundsUnderCurrency?.name,coinPrecision),
                  ),
                )}

                {/* {maskPrice(fundsUnderCurrency?.availableBalance)} */}
              </span>
            </div>
            <div className="flex flex-col mt-3">
              <span className="text-auxiliaryTextColor text-xs">
                {t('冻结余额')}
              </span>
              <span className="mt-2">
                {(maskPrice(
                  RenderUtil.FormatAmount(fundsUnderCurrency?.freezeBalance ?? 0, 8),
                ) ? maskPrice(
                  RenderUtil.FormatAmount(fundsUnderCurrency?.freezeBalance ?? 0, 8),
                ) : 0)}
              </span>
            </div>
          </div>
        </div>
      )}
      {
        <BaseSkeleton isLoaded={loading}>
          <div className="flex flex-col justify-between h-[80px] mt-2 px-4 gap-3">
            <div className="bg-backgroundAuxiliaryColor rounded-md h-full"></div>
            <div className="bg-backgroundAuxiliaryColor rounded-md h-full"></div>
            <div className="bg-backgroundAuxiliaryColor rounded-md h-full"></div>
            <div className="bg-backgroundAuxiliaryColor rounded-md h-full"></div>
          </div>
        </BaseSkeleton>
      }
    </div>
  );
};
