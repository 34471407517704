import { PhCaretRight } from '@/assets/icons/comm/PhCaretRight';
import { BaseEmpty } from '@/components/base/baseEmpty';
import BaseSkeleton from '@/components/base/baseSkeleton';
import DisplayValueDisplay from '@/components/display/displayValueDisplay';
import PageEnum from '@/enums/pageEnum';
import { getSymbolByCoinIdApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { history } from '@umijs/max';
import { useMount } from 'ahooks';
import { useTranslation } from 'react-i18next';

interface IProps {
  fundsUnderCurrency: any;
}

export default ({ fundsUnderCurrency }: IProps) => {
  const { t } = useTranslation();
  const { getSocketRowByName, sendMsg }: any = useModel('socket');
  const [urlState] = useUrlState<any>({});
  const { coinId, walletType } = urlState || {};
  const {
    data: symbol = [] as any,
    run: getSymbolByCoinIdReq,
    loading,
  } = useReq(getSymbolByCoinIdApi, {
    manual: true,
    formatResult: (res: any) => {
      if (res?.data?.length > 0) {
        sendMsg(
          `{"type":"SUB","data": ${JSON.stringify(
            res?.data?.map((i: { symbol: any }) => i?.symbol),
          )}}`,
        );
      }
      return res?.data || [];
    },
  });
  useMount(() => {
    getSymbolByCoinIdReq({
      id: coinId,
    });
  });

  return (
    <>
      <div className="px-4">
        <div className="flex justify-between items-center mb-2">
          <span className="text-base text-foreground">{t('交易')}</span>
          <PhCaretRight
            onClick={() => {
              history.push(PageEnum.TRANSACTION);
            }}
          />
        </div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-4">
          {symbol?.map((item: any) => {
            const socketData = getSocketRowByName(item?.symbol);
            return (
              <div
                key={item}
                className="rounded-md border-1 flex flex-col justify-between border-borderColor  w-full h-[110px] px-2 py-3"
              >
                <div className="flex items-center text-base">
                  <span className="text-backContrastColor">
                    {item?.alias ?? item?.symbol}
                  </span>
                </div>
                <div className="flex flex-col items-start">
                  <span className="text-backContrastColor text-base">
                    {socketData?.a}
                  </span>
                  <span className="text-xs">
                    <DisplayValueDisplay value={socketData.pe} ratio />
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!loading && symbol?.length === 0 && (
        <div className="h-[110px]">
          <BaseEmpty />
        </div>
      )}

      {
        <BaseSkeleton isLoaded={loading && (symbol as any).length === 0}>
          <div className="flex  justify-between h-[110px] px-4 gap-2">
            <div className="flex-1 bg-backgroundAuxiliaryColor rounded-md h-full"></div>
            <div className="flex-1 bg-backgroundAuxiliaryColor rounded-md h-full"></div>
          </div>
        </BaseSkeleton>
      }
    </>
  );
};
