import { CompletedOrderOrderProcessEnum } from '@/enums/businessEnum';
import { useTranslation } from 'react-i18next';
import BaseTabs from '@/components/base/baseTabs';
import { useState } from 'react';
import useUrlState from '@ahooksjs/use-url-state';
import TransferRecordList from '@/pages/funds/orderHistory/index/components/TransferRecordList';
import FlashRedemptionRecordList from '@/pages/funds/orderHistory/index/components/FlashRedemptionRecordList';
import CacheList from '@/pages/funds/orderHistory/index/components/CacheList';
import FundFlowList from '@/pages/funds/coinFundingDetails/components/FundFlowList';

export default function OrderHistoryView() {
  //获取国际化
  const { t } = useTranslation();
  /*-----------------tab栏目和内容展示-----------------------------------*/
  const [fundingRecordsTabs] = useState([
    {
      text: t('资金流水'),
      value: CompletedOrderOrderProcessEnum.ALL,
      component:FundFlowList
    },
    {
      text: t('划转'),
      value: CompletedOrderOrderProcessEnum.TRANSFER,
      component:TransferRecordList
    },
    {
      text: t('闪兑'),
      value: CompletedOrderOrderProcessEnum.SWAP,
      component:FlashRedemptionRecordList
    },
  ]);
  const [urlState] = useUrlState<any>({});
  const {
    type,
  } = urlState || {};
  const [currentTab, setCurrentTab] = useState(type ?? CompletedOrderOrderProcessEnum.ALL);

  return (
    <div className="mt-6">
      <div className="w-full">
        <div className="">
          <BaseTabs
            options={fundingRecordsTabs}
            variant={'light'}
            tabsPropsProps={{
              classNames: {
                tabList: 'px-4',
              },
            }}
            value={currentTab}
            onChange={setCurrentTab}
          />
        </div>
      </div>
      {/*这里使用display大法渲染当前tab对应的component--避免重复请求 影响体验*/}
      <div className="min-h-[40vh] px-4 mt-2">
        <CacheList currentTab={currentTab} tabs={fundingRecordsTabs}/>
      </div>
    </div>
  );
}
