import CoinFundingDetailsTradingCards from '@/pages/funds/coinFundingDetails/components/CoinFundingDetailsTradingCards';
import { getFundsUnderCurrencyApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { Divider, Image } from '@nextui-org/react';
import { useMount } from 'ahooks';
import BaseTopNav from '../../../components/base/baseTopNav';
import CoinFundingDetailsFunds from './components/CoinFundingDetailsFunds';
import CoinTransactionRecords from './components/CoinTransactionRecords';

export default () => {
  const [urlState] = useUrlState<any>({});
  const { coinId, walletType } = urlState || {};
  const {
    data: fundsUnderCurrency = {} as any,
    run: getFundsUnderCurrencyReq,
    loading,
  } = useReq(getFundsUnderCurrencyApi, {
    defaultValue: {},
    manual: true,
  });

  useMount(() => {
    getFundsUnderCurrencyReq({
      coinId,
      walletType,
    });
  });

  return (
    <>
      {/*导航*/}
      <BaseTopNav
        title={
          <div className="flex items-center justify-center">
            <Image
              className="w-[26px] h-[26px] rounded-full object-cover"
              src={fundsUnderCurrency?.icon}
            />
            <div className="ml-2">
              {fundsUnderCurrency?.alias ?? fundsUnderCurrency?.name}
            </div>
          </div>
        }
      ></BaseTopNav>
      {/*资金详情*/}
      <CoinFundingDetailsFunds
        loading={loading}
        fundsUnderCurrency={fundsUnderCurrency}
      />
      <Divider className="my-2 !bg-backgroundAuxiliaryColor" />
      {/*交易卡片*/}
      <CoinFundingDetailsTradingCards fundsUnderCurrency={fundsUnderCurrency} />
      {/*交易记录*/}
      <CoinTransactionRecords  />
    </>
  );
};
